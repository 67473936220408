import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { EdetailTargetMacStyle } from "../styles/EdetailTargetMacStyle";
import { AudioTranscript } from "../components/AudioTranscript";
import { interaction } from "../components/Helpers";
import {
	Container,
	ConvertedMarkdown,
	VideoInjector,
	Footnotes,
	Definitions,
	References,
	TabbingThrough,
} from "../components";

import { SectionCarousel } from "../components/Carousels/SectionCarousel";

const EdetailTargetMac = ({ data }) => {
	const slider = useRef();
	const [isHCP, setHCP] = useState(false);
	const dataQuery = data.allMarkdownRemark.nodes[0],
		componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
		markdownHTML = data.allMarkdownRemark.nodes[0].html,
		tabComponentItems =
			data.allMarkdownRemark.nodes[0].frontmatter.tabComponent,
		isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
		arrowText_1 =
			data.allMarkdownRemark.nodes[0].frontmatter.arrowText_1[0],
		audioTracksWithProps =
			data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
		markdownID = `ed-target-mac-content`;

	const [trackWProps, setTrackWProps] = useState("default");
	const [transcriptState, setTranscriptState] = useState(false);

	useEffect(() => {
		if (isWindow) {
			let isHCP = sessionStorage.getItem("hcp");
			if (isHCP !== "true") {
				setHCP(false);
			} else {
				setHCP(true);
			}
			let vivoMobileChart = document.getElementById("in-vivo-chart-mobile-div");
			if (vivoMobileChart) {
				vivoMobileChart.addEventListener(interaction, function (e) {
					e.stopPropagation();
				});
			}
		}
	}, [isHCP, setHCP]);
	const tmTabsSetTrackWProps = (next) => {
		let trackKey = ["default", "works"];
		setTrackWProps(trackKey[next]);
	};
	const tmSlidesSetTrackWProps = (next) => {
		let trackKey = ["default", "vivo", "systemic", "mic"];
		setTrackWProps(trackKey[next]);
	};

	return (
		<>
			<Container
				markdownID={markdownID}
				componentID={componentID}
				query={dataQuery}
				isiSnippet={isiSnippet}
				trackWProps={audioTracksWithProps[trackWProps]}
				setTrackWProps={setTrackWProps}
				transcriptState={transcriptState}
				setTranscriptState={setTranscriptState}
			>
				<EdetailTargetMacStyle id={`main`} className={`wrapper`}>
					<TabbingThrough
					 id={`tabbing-through`}
						resetSlides={(tabIndex) => {
							if (tabIndex === 0) {
								slider.current.slickGoTo(0);
							}
						}}
						tabs={tabComponentItems}
						setTrackWProps={tmTabsSetTrackWProps}
						setTranscriptState={setTranscriptState}
					/>
					<SectionCarousel
						arrowText={arrowText_1.copy}
						arrowTextISI={arrowText_1.isiSnippets}
						markdownID={markdownID}
						slider={slider}
						slidesYouWantToMake={
							dataQuery.frontmatter.sectionSlides
						}
						dataOptions={`1`}
						className={`target-mac show visible nomargin`}
						setTrackWProps={tmSlidesSetTrackWProps}
					/>
					<ConvertedMarkdown
						markdownID={markdownID}
						mdhtml={markdownHTML}
					/>
					<section id="see-how" data-tab-index="2">
						<h1>
							See how PULMOVANCE technology works
						</h1>

						<br />

						<div id="MOA-video-injector-container">
							<VideoInjector
								video={dataQuery.frontmatter.injectedVideo}
							/>
						</div>
					</section>
					<Footnotes footnotes={dataQuery.frontmatter.footnotes} />
					<Definitions
						definitions={dataQuery.frontmatter.definitions}
					/>
					<References references={dataQuery.frontmatter.references} />
					<AudioTranscript
						id={`audio-transcript-main`}
						markdownID={markdownID}
						transcriptState={transcriptState}
						setTranscriptState={setTranscriptState}
						transcriptCopy={
							audioTracksWithProps[trackWProps].transcript
						}
					/>
				</EdetailTargetMacStyle>
			</Container>
		</>
	);
};

export const query = graphql`
	{
		allMarkdownRemark(
			filter: {
				frontmatter: { markdown_id: { eq: "ed-target-mac-content" } }
			}
		) {
			nodes {
				frontmatter {
					slug
					description
					keywords
					markdown_id
					title
					components_id
					definitions
					isiSnippet
					footnotes
					references
					injectedVideo {
						id
						container
						vimeoID
					}
					audioTracksWithProps {
						default {
							trackID
							isiContent
							src
							title
							transcript
						}
						vivo {
							trackID
							isiContent
							src
							title
							transcript
						}
						systemic {
							trackID
							isiContent
							src
							title
							transcript
						}
						mic {
							trackID
							isiContent
							src
							title
							transcript
						}
						works {
							trackID
							isiContent
							src
							title
							transcript
						}
					}
					sectionSlides
					tabComponent {
						title
						isiSnippets
					}
					arrowText_1 {
						copy
						isiSnippets
					}
				}
				id
				html
			}
		}
	}
`;

export default EdetailTargetMac;
